$field-horizontal-padding: 1.2rem;
$field-vertical-padding: 1.2rem;
$field-height: 4.2rem;
$corner-radius: 0.6rem;
$text-background-color: #f8fafb;
$error-background-color: #fefafa;
$error-border-color: #bc2018;

.wrapper {
  width: 100%;

  label {
    display: inline-block;
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #3e3e3e;
    cursor: pointer;
  }

  .caption {
    margin-top: 0.8rem;
    font-size: 1.3rem;
    line-height: 1.4;
    color: #6b6e73;
    cursor: default;
  }

  .error {
    margin-top: 0.8rem;
    font-size: 1.5rem;
    color: #ee0000;
  }

  .controlWrapper {
    display: flex;

    .control {
      flex: 1 1 100%;
      height: $field-height;
      padding: $field-vertical-padding $field-horizontal-padding;

      outline: none;
      -moz-outline: none;
      -webkit-outline: none;
      border: 1px solid #ddd;
      border-radius: $corner-radius;
      background-color: #fff;

      font-size: 1.5rem;
      font-weight: 400;
      text-align: left;

      transition: border 0.15s ease-out;

      &:focus {
        border: 1px solid #000;
      }
    }

    input,
    button {
      line-height: 1;
    }

    textarea {
      min-height: 12rem;
    }

    button {
      cursor: pointer;

      &[value=""]::before {
        content: attr(placeholder);
        color: #757575;
      }
      &[value]:not([value=""])::before {
        content: attr(value);
      }
    }

    .prefix,
    .suffix {
      height: $field-height;
      padding: 0 $field-horizontal-padding;
      display: grid;
      place-items: center;

      border: 1px solid #ddd;

      cursor: default;
      transition: border 0.15s ease-out;

      .text {
        font-size: 1.5rem;
        line-height: 1;
        white-space: nowrap;
      }

      &.text {
        background-color: $text-background-color;
      }

      &.unstyled {
        padding: 0;
        background-color: transparent;
      }
    }

    .prefix {
      order: -1;
      border-radius: $corner-radius 0 0 $corner-radius;
      border-right-width: 0;

      &:not(.text) {
        padding-right: 0;
      }
    }

    .suffix {
      border-radius: 0 $corner-radius $corner-radius 0;
      border-left-width: 0;

      &:not(.text) {
        padding-left: 0;
      }
    }

    &.prefix .control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.suffix .control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .control:focus {
      & ~ .prefix,
      & ~ .suffix {
        &:not(.text) {
          border-color: #000;
        }
      }
    }
  }

  &.error {
    .control,
    .suffix:not(.text),
    .prefix:not(.text) {
      border-color: $error-border-color !important;
      background-color: $error-background-color !important;
    }
  }
}
