@use "src/styles/typography";

.base {
  height: 4.4rem;
  padding: 0.8rem 2.4rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;

  text-decoration: none;

  color: #fff;
  background-color: #000;
  border: 1.5px solid #000;
  border-radius: 0.5rem;

  cursor: pointer;
  user-select: none;

  // States
  &:hover {
  }

  &:active {
  }

  &:focus {
    outline: none;
  }
}

.block {
  width: 100%;
}

a.base {
  @extend .type--button;
}

button.base:disabled {
  background-color: gray;
  cursor: not-allowed;
  border-color: rgba(0, 0, 0, 0.15);
}

.accent {
  color: #fff;
  background-color: #bc2018;
  border-color: #bc2018;
}

.ghost {
  color: #000;
  background-color: #ededed;
  border-color: #ededed;
}
