.container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;

  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .window {
    position: relative;
    max-width: 90%;

    border-radius: 0.6rem;

    background-color: #fff;
    box-shadow: 0px 1.3px 2.7px rgba(0, 0, 0, 0.019),
      0px 3.2px 6.9px rgba(0, 0, 0, 0.027),
      0px 6.6px 14.2px rgba(0, 0, 0, 0.033),
      0px 13.5px 29.2px rgba(0, 0, 0, 0.041), 0px 37px 80px rgba(0, 0, 0, 0.06);
  }
}
