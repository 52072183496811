.container {
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0.6px 1.1px rgba(0, 0, 0, 0.04),
    0px 1.6px 3px rgba(0, 0, 0, 0.026), 0px 3.9px 7.2px rgba(0, 0, 0, 0.02),
    0px 13px 24px rgba(0, 0, 0, 0.014);
  background-color: #fff;

  pointer-events: auto;
  cursor: default;

  .body {
    display: grid;
    place-items: center;
    padding: 0.8rem 0;
  }

  .icon {
    padding: 0 1.2rem;
    display: grid;
    place-items: center;
  }

  button.dismiss {
    width: 4rem;
    height: 4rem;
    display: grid;
    place-items: center;
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    fill: #888;
  }
}

.container.error {
  .title {
    color: #bc2018;
  }
}
