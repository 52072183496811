// Spacing

$spacing: 4 8 16 24 32 40 48 56 80;
@each $sp in $spacing {
  .mt-#{$sp} {
    margin-top: #{calc($sp/10)}rem;
  }

  .mr-#{$sp} {
    margin-right: #{calc($sp/10)}rem;
  }

  .mb-#{$sp} {
    margin-bottom: #{calc($sp/10)}rem;
  }

  .ml-#{$sp} {
    margin-left: #{calc($sp/10)}rem;
  }

  .gap-#{$sp} {
    gap: #{calc($sp/10)}rem;
  }
}

// Layout
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.row.wrap,
.column.wrap {
  flex-wrap: wrap;
}

.align {
  &-center {
    text-align: center;
    justify-content: center;
  }

  &-middle {
    align-items: center;
  }

  &-left {
    text-align: left;
    justify-content: flex-start;
  }

  &-right {
    text-align: right;
    justify-content: flex-end;
  }
}
