.container {
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid black;

  display: flex;
  gap: 1.2rem;
  align-items: center;

  .label {
    font-weight: 600;
  }

  &.error {
    color: #c14236;
    border-color: #c14236;
    background-color: #fff1ef;
  }
}
