.crumbs {
  display: flex;
  gap: 0.8rem;

  p a {
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.67);
    font-weight: 400;
    transition: color 0.15s;

    &:hover {
      color: #000;
    }
  }

  img {
    opacity: 0.4;
  }
}
