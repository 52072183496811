@use "src/styles/typography";

.container {
  width: 100%;

  .passwordToggle {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    width: 3.2rem;
    height: 100%;
    display: grid;
    place-items: center;
  }

  label {
    display: block;
    margin-bottom: 0.4rem;
    color: #555;

    @extend .type--overline;
  }

  .wrapper {
    height: 4.4rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    display: flex;
    background-color: #fff;
    padding: 0 1.6rem;
    transition: all 0.2s;
    outline: 0px solid transparent;

    // box-shadow: 0px 0.1px 0px rgba(0, 0, 0, 0.02),
    //   0px 0.3px 0.1px rgba(0, 0, 0, 0.013), 0px 0.6px 0.3px rgba(0, 0, 0, 0.01),
    //   0px 2px 1px rgba(0, 0, 0, 0.007);

    .prefix {
      padding-right: 0.8rem;
      height: 4.2rem;
      display: grid;
      place-items: center;
    }

    input {
      flex-grow: 1;
      background-color: transparent;
      border-color: transparent;
      height: 100%;
      outline: none;
    }

    &:focus-within {
      background-color: #fff;
      border: 1px solid #22262e;
      outline: none;
    }
  }

  .error {
    color: #bc2018;
    margin-top: 0.4rem;
    @extend .type--body-sm;
  }

  .caption {
    margin-top: 0.4rem;
    @extend .type--body-sm;
  }
}

.container.checkbox {
  .row {
    display: flex;
    column-gap: 0.8rem;
  }

  .wrapper {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 0.2rem;
    padding: 0;
  }

  label {
    text-transform: initial;
    margin-bottom: 0;
    letter-spacing: initial;
  }
}
