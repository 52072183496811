@use "src/styles/typography";

.container {
  display: inline-block;

  .link {
    display: flex;
    align-items: center;
  }

  .mark {
    flex-grow: 0;
    flex-shrink: 0;
    display: grid;
    place-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 12.5%;

    svg {
      width: 65%;
      height: 65%;
    }
  }

  .mark + .text {
    margin-left: 0.6em;
  }

  .text {
    font-weight: 600;
    white-space: nowrap;
    @extend .type--display;
  }

  &.dark {
    .mark {
      background-color: rgba(0, 0, 0, 0.87);

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &.light {
    .mark {
      background-color: #fff;

      svg {
        path {
          fill: #000;
        }
      }
    }

    .text {
      color: #fff;
    }
  }
}
