.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  pointer-events: none;
}

.stack {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.8rem;
  width: 100%;
  max-width: 42rem;
  align-items: center;
}

.toastWrapper {
  position: absolute;
  bottom: 0;
  right: 0;

  &:nth-last-child(2) {
    bottom: 1.6rem;
  }

  &:nth-last-child(3) {
    bottom: 3.2rem;
  }

  &:nth-last-child(n + 3) > div {
    box-shadow: none;
  }
}

.top {
  top: 2.4rem;
  flex-direction: column-reverse;
}

.bottom {
  bottom: 2.4rem;
}

.left {
  left: 2.4rem;
  align-items: flex-start;
}

.middle {
  left: 50%;
  transform: translateX(-50%);
}

.right {
  right: 2.4rem;
  align-items: flex-end;
}
