$root-size: 62.5%;
$font-fallback: Roboto, Arial, Helvetica, sans-serif;
$font-ui: "Inter", $font-fallback;
$font-display: "Space Grotesk", $font-fallback;

html {
  font-size: $root-size;
}

body {
  font-family: $font-ui;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

input,
button,
textarea,
select {
  font: inherit;
}

.type {
  &--title {
    font-size: 3.6rem;
    font-weight: 500;
    letter-spacing: -0.065rem;
    line-height: 1.25;

    &-lg {
      font-size: 4.32rem;
      font-weight: 600;
      letter-spacing: -0.1rem;
      line-height: 1.1;
    }

    &-sm {
      font-size: 3rem;
      font-weight: 500;
      letter-spacing: -0.065rem;
      line-height: 1.25;
    }
  }

  &--heading {
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: -0.065rem;
    line-height: 1.25;

    &-lg {
      font-size: 2.88rem;
      font-weight: 500;
      letter-spacing: -0.065rem;
      line-height: 1.25;
    }

    &-sm {
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: -0.065rem;
      line-height: 1.25;
    }
  }

  &--body {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;

    &-lg {
      font-size: 1.92rem;
      font-weight: 400;
      line-height: 1.5;
    }

    &-sm {
      font-size: 1.33rem;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  &--overline {
    font-size: 1.33rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  &--button {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &--display {
    font-family: $font-display;
  }
}

// Common Globals

p,
li,
a,
input {
  @extend .type--body;
}

p a {
  font-weight: 500;
}

a {
  text-decoration: none;
}

h1 {
  @extend .type--title-lg;
}

h2 {
  @extend .type--title;
}

h3 {
  @extend .type--title-sm;
}

h4 {
  @extend .type--heading-lg;
}

h5 {
  @extend .type--heading;
}

h6 {
  @extend .type--heading-sm;
}

button {
  @extend .type--button;
}
