$text-dark: #0f172a;
$text-light: #64748b;
$text-lighter: #999999;

a {
  color: $text-dark;
}

body {
  color: $text-dark;
}
